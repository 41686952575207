import { AVATAR_IMAGE_URLS } from '~/components/homepage/HwSeeWhoIsGoing/constants';

export const CARD_ITEMS = [
  {
    frameUrl: 'https://a.hwstatic.com/video/upload/q_50/v1644569502/pwa/linkup/linkups-bike.mp4',
    name: 'Jenny',
    title: 'Bike Tour',
    time: 'Tomorrow',
    location: 'Plaza',
    participantsCount: 12,
    avatarUrl: AVATAR_IMAGE_URLS.jenny,
    participantsAvatarUrls: [AVATAR_IMAGE_URLS.nathan, AVATAR_IMAGE_URLS.pan, AVATAR_IMAGE_URLS.pierre],
    thumbnailUrl: 'https://a.hwstatic.com/image/upload/f_auto,h_72,q_50/v1644507377/pwa/linkup/bike-tours-xs.png',
  },
  {
    frameUrl: 'https://a.hwstatic.com/video/upload/q_50/v1644569502/pwa/linkup/linkups-surf.mp4',
    name: 'Anna',
    title: 'Surf Class',
    time: 'Today',
    location: 'Hostel hall',
    participantsCount: 10,
    avatarUrl: AVATAR_IMAGE_URLS.anna,
    participantsAvatarUrls: [AVATAR_IMAGE_URLS.luiza, AVATAR_IMAGE_URLS.victoria, AVATAR_IMAGE_URLS.allesandra],
    thumbnailUrl: 'https://a.hwstatic.com/image/upload/f_auto,h_72,q_50/v1644507377/pwa/linkup/surf-small.png',
  },
  {
    frameUrl:
      'https://a.hwstatic.com/image/upload/f_auto,h_415,c_scale,q_50/v1644507379/pwa/linkup/Che_Tulum_Hostel_Bar_Tulum_Mexico-md.jpg',
    name: 'Victoria',
    title: 'Cenote Adventure',
    time: 'Friday',
    location: 'City centre',
    participantsCount: 19,
    avatarUrl: AVATAR_IMAGE_URLS.victoria,
    participantsAvatarUrls: [AVATAR_IMAGE_URLS.lin, AVATAR_IMAGE_URLS.nathan, AVATAR_IMAGE_URLS.allesandra],
    thumbnailUrl:
      'https://a.hwstatic.com/image/upload/f_auto,h_72,q_50/v1644507377/pwa/linkup/Che_Tulum_Hostel_Bar_Tulum_Mexico-xs.jpg',
  },
  {
    frameUrl: 'https://a.hwstatic.com/video/upload/q_50/v1644569502/pwa/linkup/linkups-dunes.mp4',
    name: 'Lucca',
    title: 'Dune surfing',
    time: 'Saturday',
    location: 'White Sands',
    participantsCount: 6,
    avatarUrl: AVATAR_IMAGE_URLS.luka,
    participantsAvatarUrls: [AVATAR_IMAGE_URLS.tom, AVATAR_IMAGE_URLS.luiza, AVATAR_IMAGE_URLS.mike],
    thumbnailUrl: 'https://a.hwstatic.com/image/upload/f_auto,h_72,q_50/v1644507377/pwa/linkup/dune-surf-xs.png',
  },
  {
    frameUrl: 'https://a.hwstatic.com/image/upload/f_auto,h_415,c_scale,q_50/v1644507377/pwa/linkup/beach-yoga.jpg',
    name: 'Luiza',
    title: 'Morning Beach Yoga',
    time: 'Sunday',
    location: 'Palm Beach',
    participantsCount: 4,
    avatarUrl: AVATAR_IMAGE_URLS.luiza,
    participantsAvatarUrls: [AVATAR_IMAGE_URLS.victoria, AVATAR_IMAGE_URLS.tom, AVATAR_IMAGE_URLS.eve],
    thumbnailUrl: 'https://a.hwstatic.com/image/upload/f_auto,h_72,q_50/v1644507377/pwa/linkup/beach-yoga-xs.jpg',
  },
];
